<template>
    <section class="pr-3 pt-4">
        <div class="row mx-0">
            <div class="col shadow-15 py-3 px-3 br-12 bg-white">
                <p class="text-general f-18 f-600">
                    Bono fúturo
                </p>
                <p class="mt-1 text-general2 f-14 f-400 px-3">
                    El {{ $config.cliente }} podrá tener el beneficio de un bono siempre y cuando realice una compra inicial que cumpla con ciertas condiciones.
                </p>
            </div>
            <div class="col shadow-15 d-flex bg-white px-3 py-3 ml-3 br-12">
                <div class="col border-right text-center">
                    <p class="text-general f-30 f-600 br-20 px-3 py-1 mx-1">
                        {{ cupon.aplica_para }}
                    </p>
                    <p class="text-general f-14">
                        Aplica para
                    </p>
                </div>
                <div class="col border-right text-center">
                    <p class="text-general f-30 f-600 br-20 px-3 py-1 mx-1">
                        {{ cupon.disponible }}
                    </p>
                    <p class="text-general f-14">
                        Disponible para
                    </p>
                </div>
                <div class="col text-center">
                    <p class="text-general f-30 f-600 br-20 px-3 py-1 mx-1">
                        {{ cupon.usado }}
                    </p>
                    <p class="text-general f-14">
                        Tomados por
                    </p>
                </div>
                <!-- <div class="justify-content-end h-100 mx-0">
                    <div class="d-middle justify-content-end h-100 mx-0">
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-12 px-0 mt-4">
            <!-- <div class="row mx-0  pt-2">
                <div
                class="col-4 pt-0 d-middle-center cr-pointer"
                :class="tab=='cupon1'? 'border-b-red text-general-red':'border-bottom'"
                @click="tab='cupon1'"
                >
                    Primer Cupón
                </div>
                <div
                class="col-4 pt-0 d-middle-center cr-pointer"
                :class="tab=='cupon2'? 'border-b-red text-general-red':'border-bottom'"
                @click="tab='cupon2'"
                >
                    Cupón Futuro
                </div>
            </div> -->
            <div class="mx-0">
                <datos-cupon />
                <datos-bono />
            </div>
        </div>
    </section>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    components: {
        datosCupon: () => import('./componentes/datosCupon'),
        datosBono: () => import('./componentes/datosBono'),
    },
    data(){
        return {
            tab:'cupon1',
            url_image: 'https://www.eluniversal.com.mx/sites/default/files/2021/05/06/bandera-de-colombia.jpg'
        }
    },
    computed:{
        ...mapGetters({
            cupon: 'cupones/cupones/datosDetalle',
            //cupon: 'cupones/cupones/datosDetalle',

        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
    }

}
</script>
<style lang="scss" scoped>
.shadow-15{
    box-shadow: 0px 1px 4px #15223214;
}
</style>
